body {
  margin: 0;
  padding: 0;
  height: 100vh;
  overflow: hidden;
}

.wave-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--sky-1);
  overflow: hidden;
}

.wave {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.5;
}

.wave-1 {
  background-color: var(--sky-3);
  clip-path: polygon(
    0% 60%, 15% 65%, 30% 58%, 45% 63%, 60% 57%, 75% 62%, 90% 56%, 100% 60%, 100% 100%, 0% 100%
  );
}

.wave-2 {
  background-color: var(--sky-5);
  clip-path: polygon(
    0% 70%, 10% 75%, 25% 68%, 40% 73%, 55% 67%, 70% 72%, 85% 66%, 100% 70%, 100% 100%, 0% 100%
  );
}

.wave-3 {
  background-color: var(--sky-7);
  clip-path: polygon(
    0% 80%, 20% 85%, 35% 78%, 50% 83%, 65% 77%, 80% 82%, 95% 76%, 100% 80%, 100% 100%, 0% 100%
  );
}

.wave-4 {
  background-color: var(--sky-9);
  clip-path: polygon(
    0% 90%, 15% 95%, 30% 88%, 45% 93%, 60% 87%, 75% 92%, 90% 86%, 100% 90%, 100% 100%, 0% 100%
  );
}

.stars {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 50%;
  background-image: 
    radial-gradient(2px 2px at 21px 30px, var(--amber-12), rgba(0,0,0,0)),
    radial-gradient(2px 2px at 40px 70px, var(--orange-12), rgba(0,0,0,0)),
    radial-gradient(2px 2px at 53px 160px, var(--amber-12), rgba(0,0,0,0)),
    radial-gradient(2px 2px at 97px 40px, var(--sky-12), rgba(0,0,0,0)),
    radial-gradient(2px 2px at 131px 80px, var(--orange-12), rgba(0,0,0,0)),
    radial-gradient(2px 2px at 169px 120px, var(--amber-12), rgba(0,0,0,0));
  background-repeat: repeat-x;
  background-size: 200px 200px;
}

.content-container {
  position: relative;
  z-index: 1;
}

.content-section {
  background-color: var(--black-a6);
  backdrop-filter: blur(10px);
  border-radius: .7rem;
}